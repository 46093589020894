import { createApp, nextTick } from "vue";
import VueScrollTo from 'vue-scrollto' //https://github.com/rigor789/vue-scrollto
import VueKinesis from "vue-kinesis"; //https://github.com/Aminerman/vue-kinesis

import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'

import router from './router.js'
import store from './store.js'
import i18n from './i18n.js'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(i18n)
app.use(VueKinesis)

app.use(VueScrollTo, {
    container: "body",
    duration: 1000,
    lazy: false,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

app.mount('#app')
