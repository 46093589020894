<template>
  <div :ref="sentinalName" class="w-full h-px relative">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "IntersectionObserver",
  emits: ['on-intersection-element'],
  props: {
    sentinalName: {
      type: String,
      required: true,
    },
    sentinalBg: {
      type: String,
    },
    sentinalId: {
      type: Number,
    },
    threshold: {
      type: Number,
    },
  },
  data() {
    return {
      isIntersectingElement: false,
    };
  },
  watch: {
    isIntersectingElement: function (value) {
      this.$emit("on-intersection-element", {value: value, bg: this.sentinalBg, id: this.sentinalId});
    },
  },
  mounted() {
    const sentinal = this.$refs[this.sentinalName];

    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        this.isIntersectingElement = true;
      } else {
        this.isIntersectingElement = false;
      }
    };

    let options = {
      threshold: this.threshold || .5
    };

    if (this.threshold && this.threshold < 0) {
      delete options.threshold
    }

    const observer = new window.IntersectionObserver(handler, options);

    observer.observe(sentinal);
  },
};
</script>
