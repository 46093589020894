<template>
  <div class="tl" :class="{hidden: menuShowed && !showDiplomas && !showCertificates}">
    <span class="logo hover" v-if="article.id != 0" @click="closeArticle()" :class="{'logo--black': logoBlackStep1 && logoBlackStep2}">ТатьяHа<br> Чалик</span>
    <span class="logo" v-scroll-to="{el: '#app'}" v-if="article.id == 0" @click="showCertificates=false; showDiplomas=false; menuShowed=false">ТатьяHа<br> Чалик</span>
  </div>

  <div class="tr hover" @click="toggleMenu()">
    <div class="hamburger" :class="{close: showCertificates || showDiplomas, open: menuShowed}">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div class="page page--home" id="page">

    <template v-if="article.id == 0">
      <template v-for="section in sections">
        <intersection-observer :sentinal-name="section.name" :sentinal-bg="section.bg" :sentinal-id="section.id" @on-intersection-element="onIntersectionSection">
          <kinesis-container>
            <div :id="section.name" class="section" :class="section.classes" :style="{color: section.text.color}">
              <div>
                <div class="title-outer"><div class="title-inner">
                  <div class="title" v-html="section.title" :class="{show: sectionActive == section.id, up: sectionActive > section.id}"></div>
                </div></div>
                <div class="pic"><div :style="bgImgStyle(section.pic)"></div></div>

                <div class="btn-fly">
                  <div class="btn" :style="{background: section.btn.bg, color: section.btn.color}" @click="articleLoad(section)"><span>
                    <span v-html="section.btn.text"></span>
                  </span></div>
                </div>

                  <div class="square"><kinesis-element :strength="-10"><div class="fly" :style="{background: section.forms.square}"></div></kinesis-element></div>
                  <div class="line"><kinesis-element :strength="20"><div class="fly" :style="{background: section.forms.line}"></div></kinesis-element></div>
              </div>
            </div>
          </kinesis-container>
        </intersection-observer>
      </template>
    </template>
    <template v-else>
      <kinesis-container>
      <article class="article">
        <intersection-observer sentinal-name="article" :threshold="-1" @on-intersection-element="onIntersectionArticleStart">
          <div class="article__bg"><div :style="articleBgStyle(article)"></div></div>
        </intersection-observer>
        <div class="article__name title-magic"><span v-html="article.title"></span></div>

        <div class="article__voile" :style="{background: article.article.bg}" :class="{'hover-black': article.article.bg_text == '#000' || article.article.bg_text == '#000000'}">
          <intersection-observer sentinal-name="article" :threshold="-1" @on-intersection-element="onIntersectionArticle"></intersection-observer>
        </div>

        <div class="article__content">
          <div class="article__phrase" v-if="article.phrase" :style="{background: article.article.block, color: article.article.block_text}"><div v-html="article.phrase"></div></div>
          <div class="article__phrase2" v-if="article.phrase2" v-html="article.phrase2" :style="{color: article.article.bg_text}" :class="{'hover-black': article.article.bg_text == '#000' || article.article.bg_text == '#000000'}"></div>
          <div class="article__phrase2" v-if="!article.phrase2 && article.phrase" :class="{'hover-black': article.article.bg_text == '#000' || article.article.bg_text == '#000000'}"></div>
          <div class="article__text hover-black" v-html="article.text"></div>
          <div class="article__back"><span class="hover" @click="closeArticle()">{{ get('back') }}</span></div>
        </div>

        <div class="square square--1" :class="{loaded: articleLoaded}"><kinesis-element :strength="10"><div class="fly" :style="{background: articleSquare1}"></div></kinesis-element></div>
        <div class="square square--2" :class="{loaded: articleLoaded}"><kinesis-element :strength="-10"><div class="fly" :style="{background: articleSquare2}"></div></kinesis-element></div>
        <div class="line line--1" :class="{loaded: articleLoaded}"><kinesis-element :strength="-20"><div class="fly" :style="{background: articleLine1}"></div></kinesis-element></div>
        <div class="line line--2" :class="{loaded: articleLoaded}"><kinesis-element :strength="20"><div class="fly" :style="{background: articleLine2}"></div></kinesis-element></div>
      </article>
      </kinesis-container>
    </template>

  </div>

  <div class="bg"><div :style="{background: bg}"></div></div>

  <transition :enter-active-class="$store.state.enterClassName" :leave-active-class="$store.state.leaveClassName">
    <div class="menu" v-if="menuShowed == true">
      <transition :enter-active-class="$store.state.enterClassName2" :leave-active-class="$store.state.leaveClassName2">
        <div class="menu__inner" v-if="!showCertificates && !showDiplomas">
          <template v-for="item in sections">
            <template v-if="false">
    <!--        <div  class="menu-item link" v-html="item.menu" v-scroll-to="{el: '#section-' + item.id}" @click="menuItemClick()"-->
    <!--              v-on:mouseover="menuHoverId = item.id"-->
    <!--        ></div>-->
            </template>

            <div  class="menu-item link" v-html="item.menu" @click="menuItemClick(item)"
                  v-on:mouseover="menuHoverId = item.id"
            ></div>
          </template>
          <div class="menu-float">
            <template v-for="item in sections">
              <div class="menu-float-item link" :style="bgImgStyle(item.pic)" :class="{active: item.id == menuHoverId}"></div>
            </template>
          </div>
        </div>
      </transition>

      <transition :enter-active-class="$store.state.enterClassName2" :leave-active-class="$store.state.leaveClassName2">
        <div class="slider-outer" v-if="showCertificates || showDiplomas">
          <swiper
              v-if="showCertificates && !isMobile"
              :slides-per-view="'auto'"
              :scrollbar="{ draggable: true }"
              :navigation="true"
          >
            <swiper-slide v-for="img in certificates"><img class="slider-item" :src="imgSrc(img.pic)"></swiper-slide>
          </swiper>
          <swiper
              v-if="showDiplomas && !isMobile"
              :slides-per-view="'auto'"
              :scrollbar="{ draggable: true }"
              :navigation="true"
          >
            <swiper-slide v-for="img in diplomas"><img class="slider-item" :src="imgSrc(img.pic)"></swiper-slide>
          </swiper>

          <template v-if="isMobile && showCertificates">
            <img v-for="img in certificates" class="slider-item" :src="imgSrc(img.pic)">
          </template>
          <template v-if="isMobile && showDiplomas">
            <img v-for="img in diplomas" class="slider-item" :src="imgSrc(img.pic)">
          </template>
        </div>
      </transition>

      <div class="menu__bottom" v-on:mouseover="menuHoverId = false">
        <div>
          <span class="menu__link link" :class="{active: showCertificates}" @click="showCertificates = true; showDiplomas = false">{{ get('certificatesBtn') }}</span>
          <span class="menu__link link" :class="{active: showDiplomas}" @click="showDiplomas = true; showCertificates = false">{{ get('diplomasBtn') }}</span>
        </div>
        <div>
          <a :href="get('phone_link')" :class="{hidden: isMobile && (showCertificates || showDiplomas)}">{{ get('phone') }}</a>
          <template v-if="!isMobile">/{{ get('address') }}</template>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';
import IntersectionObserver from "./include/IntersectionObserver.vue";

import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/less";
import 'swiper/less/scrollbar';
import "swiper/less/navigation"

// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  name: 'Home',
  mixins: [loader, isMobile, functions],
  emits: ['loaded', 'onBgChanged'],
  components: {
    IntersectionObserver,
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      pageData: {page: 'Home'},
      diplomas: {},
      certificates: {},
      sections: {},
      sectionActive: 1,
      mouseX: 0,
      mouseY: 0,
      voile: '',
      voileBack: '',
      scrollTop: 0,
      articleCurrent: 0,
      articleLoaded: false,
      article: {
        id: 0
      },
      menuShowed: false,
      menuHoverId: false,
      logoBlackStep1: false,
      logoBlackStep2: false,
      articleLine1: '',
      articleLine2: '',
      articleSquare1: '',
      articleSquare2: '',
      colors: [],
      bg: '',
      showDiplomas: false,
      showCertificates: false,
    }
  },

  activated() {
    let _t = this;

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScrollPic);

    $(document).mousemove(function(e){
      _t.mouseX = e.pageX;
      _t.mouseY = e.pageY;
    });

    _t.$store.subscribe((mutation, state) => {
      if (mutation.type === 'articleLoaded') {
        _t.onArticleLoad();
      }
    });

    $('body').mousemove(function(e){
      let elem = $(".menu-float");
      let top = e.clientY/* - elem.height() / 2*/;

      top += $('.menu').scrollTop();

      TweenMax.to(elem, 2, {left:e.clientX/* - elem.width() / 3*/, top:top, ease:Elastic.linear});
    });
  },

  deactivated() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleScrollPic);
  },

  computed: {},

  watch: {},

  methods: {
    handleScroll() {
      let scrollTop = $(window).scrollTop();

      if ($('.section.active').length != 0) {
        let topPosition = scrollTop - $('.section.active').position().top;

        $('.section.active .square').css({
          transform: 'translateY(' + (topPosition * 1.1 * -1) + 'px)'
        });
        $('.section.active .line').css({
          transform: 'translateY(' + (topPosition * -1) + 'px)'
        });

        $('.section--1 > div').css({
          transform: 'translateY(' + scrollTop / 1.5 + 'px)'
        });
        $('.section--1 .title-outer').css({
          'margin-top': scrollTop / 1.5 + 'px'
        });
      } else {
        let bgArticle = $('.article__bg');

        bgArticle.css({
          transform: 'translateY(' + (scrollTop / 3) + 'px)'
        });

        $('.article .square').css({
          transform: 'translateY(' + (scrollTop / 4 * -1) + 'px)'
        });
        $('.article .line').css({
          transform: 'translateY(' + (scrollTop / 2 * -1) + 'px)'
        });
      }
    },
    handleScrollPic() {
      const _t = this;

      if (_t.article.id != 0) {
        return;
      }

      let scrollTop = $(window).scrollTop();
      let sectionTop = $('.section--' + _t.sectionActive).position().top;

      /*$('.section--' + _t.sectionActive).find('.pic div').css({
        transform: 'translateY(' + ((scrollTop - sectionTop)/2 * -1) + 'px)'
      });*/
      $('.section--' + _t.sectionActive).find('.pic').css({
        transform: 'translateY(' + ((scrollTop - sectionTop)/2 * -1) + 'px)'
      });
    },
    onIntersectionSection(data) {
      const _t = this;

      if (_t.article.id != 0) {
        return;
      }

      if (data.value) {
        _t.sectionActive = data.id;
        //_t.$emit("onBgChanged", data.bg);
        _t.bg = data.bg;

        for (let i in _t.sections) {
          _t.sections[i].classes.prev = false;
          _t.sections[i].classes.next = false;

          if (_t.sections[i].id == _t.sectionActive) {
            _t.sections[i].classes.active = true;
          } else {
            _t.sections[i].classes.active = false;

            if (_t.sections[i].id < _t.sectionActive) {
              _t.sections[i].classes.prev = true;
            } else {
              _t.sections[i].classes.next = true;

            }
          }
        }
      }
    },
    onIntersectionArticleStart(data) {
      const _t = this;

      if (_t.article.id == 0) {
        return;
      }

      _t.logoBlackStep1 = !data.value && (_t.article.article.bg_text == '#000' || _t.article.article.bg_text == '#000000');
    },
    onIntersectionArticle(data) {
      const _t = this;

      if (_t.article.id == 0) {
        return;
      }

      _t.logoBlackStep2 = data.value && (_t.article.article.bg_text == '#000' || _t.article.article.bg_text == '#000000');
    },
    onActivated() {
      var _t = this;

      _t.sections = _t.get('sections');
      _t.diplomas = _t.get('diplomas');
      _t.certificates = _t.get('certificates');

      for (let i in _t.sections) {
        _t.sections[i].name = 'section-' + _t.sections[i].id;
        _t.sections[i].classes = {};
        _t.sections[i].classes['section--' + _t.sections[i].id] = true;
        _t.sections[i].classes['active'] = _t.sectionActive ==  _t.sections[i].id;
        _t.sections[i].classes['prev'] = false;
        _t.sections[i].classes['next'] = _t.sectionActive !=  _t.sections[i].id;

        if (_t.sections[i].text == undefined) {
          _t.sections[i].text = {};
        }
        if (_t.sections[i].text.color == undefined) {
          _t.sections[i].text.color = '#fff';
        }

        if (_t.sections[i].btn == undefined) {
          _t.sections[i].btn = {};
        }
        if (_t.sections[i].btn.bg == undefined) {
          _t.sections[i].btn.bg = '#000';
        }
        if (_t.sections[i].btn.color == undefined) {
          _t.sections[i].btn.color = '#fff';
        } else {
          _t.checkColor(_t.sections[i].btn.color);
        }
        if (_t.sections[i].btn.text == undefined) {
          _t.sections[i].btn.text = _t.get('more');
        }

        _t.checkColor(_t.sections[i].bg);
        _t.checkColor(_t.sections[i].voile);
        _t.checkColor(_t.sections[i].voile_back);
        _t.checkColor(_t.sections[i].forms.square);
        _t.checkColor(_t.sections[i].forms.line);
      }

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        var images = [];

        for (let i in _t.sections) {
          if (_t.sections[i].pic) {
            for (let j in _t.sections[i].pic) {
              images.push(_t.sections[i].pic[j]);
            }
          }
        }
        for (let i in _t.certificates) {
          if (_t.certificates[i].pic) {
            for (let j in _t.certificates[i].pic) {
              images.push(_t.certificates[i].pic[j]);
            }
          }
        }
        for (let i in _t.diplomas) {
          if (_t.diplomas[i].pic) {
            for (let j in _t.diplomas[i].pic) {
              images.push(_t.diplomas[i].pic[j]);
            }
          }
        }

        _t.loadImages(images);
      }

      _t.btnFly();
    },
    checkColor(color){
      const _t = this;

      if (['#000', '#fff', '#000000', '#ffffff'].indexOf(color) == -1 && _t.colors.indexOf(color) == -1) {
        _t.colors.push(color);
      }
    },
    btnFly(){
      let _t = this;

      if (_t.isMobile) {
        return;
      }

      setTimeout(function () {
        $('.btn-fly').each(function() {
          var xp = 0, yp = 0, rx, ry, loop;

          let fly = $(this).find('.btn');

          const w = fly.width();

          fly.mousemove(function(){
            var parentOffset = $(this).offset();
            rx = (_t.mouseX - (parentOffset.left))*2;
            ry = (_t.mouseY - (parentOffset.top))*2;
          }).mouseover(function(){
            clearInterval(loop);
            loop = setInterval(function(){
              xp += ((rx-w) - xp) / 4-1;
              yp += ((ry-w) - yp) / 4-1;

              fly.css({left:xp, top:yp});
            }, 30);
          }).mouseleave(function(e){
            clearInterval(loop);
            loop = setInterval(function(){
              xp += (0 - xp) / 3-1;
              yp += (0 - yp) / 3-1;

              fly.css({left:xp, top:yp});
            }, 30);
          });
        });
      }, 1000);
    },
    articleLoad(section) {
      const _t = this;

      //_t.$emit("onBgChanged", 'var(--bg)');
      _t.bg = 'var(--bg)';

      _t.voile = section.voile;
      _t.voileBack = section.voile_back;
      _t.articleCurrent = section.id;
      _t.scrollTop = $(window).scrollTop();

      $('.cursor').addClass('cursor--crazy');
      $('.cursor-dot').addClass('cursor--tiny');

      setTimeout(function() {
        $('.cursor-dot').css({'background': _t.voile}).addClass('cursor--crazy cursor--top');

        setTimeout(function() {
          let params = { id: _t.articleCurrent };

          console.log('start load article')
          _t.$store.dispatch('loadArticle', params);
        }, 1500);
      }, 100);
    },
    onArticleLoad() {
      const _t = this;

      console.log('end load article')

      _t.article = _t.$store.state.articles[_t.articleCurrent];

      _t.articleSquare1 = _t.colors[_t.randomInt(_t.colors.length - 1)];
      _t.articleSquare2 = _t.colors[_t.randomInt(_t.colors.length - 1)];
      _t.articleLine1 = _t.colors[_t.randomInt(_t.colors.length - 1)];
      _t.articleLine2 = _t.colors[_t.randomInt(_t.colors.length - 1)];

      _t.magic();

      $('.cursor-dot').removeClass('cursor--tiny cursor--crazy cursor--hover cursor--black');

      setTimeout(function() {
        $('.cursor').removeClass('cursor--crazy');

        $(window).scrollTop(0);
        _t.articleLoaded = true;

        setTimeout(function () {
          $('.cursor').removeClass('cursor--crazy cursor--hover cursor--big cursor--filled cursor--black');
          $('.cursor-dot').css({background: ''}).removeClass('cursor--top');
        }, 1000);
      }, 500);
    },
    closeArticle_OLD() {

      const _t = this;

      _t.magicComplete = false;

      $('.cursor').addClass('cursor--filled cursor--crazy');
      $('.cursor-dot').addClass('cursor--tiny');

      $('.cursor').removeClass('cursor--hover');
      $('.cursor-dot').removeClass('cursor--hover');

      $('.cursor-dot').css({'background': _t.voile}).addClass('cursor--crazy cursor--top');

      setTimeout(function() {
        _t.article = {id:0};
        _t.articleCurrent = 0;

        $('.cursor-dot').removeClass('cursor--tiny cursor--crazy cursor--hover cursor--black');

        setTimeout(function() {
          $('.cursor').removeClass('cursor--crazy');

          $(window).scrollTop(_t.scrollTop);

          setTimeout(function () {
            $('.cursor').removeClass('cursor--crazy cursor--hover cursor--big cursor--filled cursor--black');
            $('.cursor-dot').css({background: ''}).removeClass('cursor--top');

            _t.btnFly();
            _t.handleScroll();
            _t.handleScrollPic();
          }, 1000);
        }, 500);
      }, 1500);

    },
    closeArticle() {

      const _t = this;

      _t.magicComplete = false;

      $('.cursor').removeClass('cursor--hover');
      $('.cursor-dot').removeClass('cursor--hover');

      $('#slide').css({'background': _t.voileBack}).addClass('slide--step1');

      setTimeout(function() {
        _t.article = {id:0};
        _t.articleCurrent = 0;
        _t.articleLoaded = false;

        _t.menuShowed = false;
        _t.showDiplomas = false;
        _t.showCertificates = false;

        $('#slide').addClass('slide--step2');

        setTimeout(function() {
          $(window).scrollTop(_t.scrollTop);

          setTimeout(function() {
            _t.btnFly();
            _t.handleScroll();
            _t.handleScrollPic();
          }, 200);

          setTimeout(function() {
            $('#slide').removeClass('slide--step2 slide--step1').removeAttr('style');
          }, 1000);
        }, 100);
      }, 1000);
    },
    toggleMenu() {
      let _t = this;

      if (_t.showCertificates || _t.showDiplomas) {
        _t.showCertificates = false;
        _t.showDiplomas = false;
        return;
      }

      $('body').toggleClass('open');

      _t.menuShowed = !_t.menuShowed;
    },
    closeMenu() {
      let _t = this;

      _t.menuShowed = false;
      $('body').removeClass('open');
    },
    menuItemClick(section) {
      const _t = this;

      _t.article = {id:0};
      _t.articleCurrent = 0;

      $('.cursor').removeClass('cursor--hover cursor--big');
      $('.cursor-dot').removeClass('cursor--hover');

      setTimeout(function () {
        _t.menuShowed = false;
        $('body').removeClass('open');

        if (section) {
          _t.articleLoad(section);
        }
      }, 100);
    },
    articleBgStyle(article) {
      const _t = this;

      let style = _t.bgImgStyle(article.pic);

      if (article.position) {
        style['background-position'] = article.position;
      }

      return style;
    }
  }
}
</script>
