export const loader = {
    data() {
        return {
            isLoaded: false,
            imagesLoaded: false,
            isSubPageLoaded: false,
            magicComplete: false,
        }
    },

    deactivated() {
        this.isLoaded = false;

        $('.animate__fadeInUpSmall').removeClass('animate__fadeInUpSmall');
    },

    activated() {
        let _t = this;

        if (_t.$store.state.pagesLoaded.App && _t.$store.state.pagesLoaded[_t.pageData.page]) {
            _t.onActivated();
        } else {
            _t.$store.commit('loaderON');

            _t.$store.subscribe((mutation, state) => {
                if (mutation.type === 'pageLoaded' && mutation.payload.page == _t.pageData.page) {
                    _t.onActivated();
                }
            });
        }
    },

    created(){
        let _t = this;

        let params = _t.pageData;

        if (_t.id != undefined) {
            params.id = _t.id;
        }

        this.$store.dispatch('loadData', params);
    },
    methods: {
        pageLoaded: function(){
            let _t = this;

            let timeOut = _t.$store.state.loader == true ? 500 : 0;

            if (_t.isMobile) {
                if (_t.$store.state.loader == true && (_t.$refs.ps || _t.$refs.scrollbar)) {
                    timeOut += 500;
                }

                if (_t.$refs.ps) {
                    _t.$refs.ps.destroy();
                }
                if (_t.$refs.scrollbar) {
                    _t.$refs.scrollbar.destroy();
                }
            }

            setTimeout(function () {
                _t.$store.commit('loaderOFF');

                setTimeout(function () {
                    _t.isLoaded = true;
                    _t.isSubPageLoaded = true;
                    _t.$emit('loaded', _t.pageData);
                }, 100);
            }, timeOut);
        },
        loadImages: function(images, skipPageLoaded = false){
            var _t = this;
            var i,j,objImage;
            var imagesCnt = images.length;
            var imagesLoaded = 0;

            if (images.length) {
                if (!skipPageLoaded) {
                    _t.$store.commit('loaderON');
                }

                for (i in images) {
                    objImage = new Image();

                    objImage.onload = function () {
                        imagesLoaded++;

                        if (imagesLoaded == imagesCnt) {
                            _t.imagesLoaded = true;
                            if (!skipPageLoaded) {
                                _t.pageLoaded();
                            }
                        }
                    };

                    objImage.src = images[i];
                }
            } else {
                _t.imagesLoaded = true;
                if (!skipPageLoaded) {
                    _t.pageLoaded();
                }
            }
        },
    }
};
