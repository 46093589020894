import { createStore } from 'vuex'
import axios from "axios";
import { ref } from 'vue'
import router from './router.js'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            apiUrl: '/api/api.php',
            enterClassName: 'animate__animated animate__slideInLeft',
            leaveClassName: 'animate__animated animate__slideOutRight',
            enterClassName2: 'animate__animated animate__slideInRight',
            leaveClassName2: 'animate__animated animate__slideOutLeft',
            loader: ref(false),
            pageLoaded: ref(false),
            articleLoaded: ref(false),
            pagesLoaded: ref({}),
            pages: ref({}),
            articles: ref({}),
        }
    },
    mutations: {
        pageLoaded: function (state, params) {
            state.pageLoaded = true;

            state.pagesLoaded[params.page] = true;
        },
        articleLoaded: function (state, params) {
            state.articleLoaded = true;
        },
        loaderON(state) {
            state.loader = true;
            console.log('loader is ON')
        },
        loaderOFF(state) {
            state.loader = false;
            console.log('loader is OFF')
        }
    },
    actions: {
        async loadData ({ commit, state }, params) {

            let urlParams = {
                action: 'loadData',
                page: params.page
            }

            if (params.id) {
                urlParams.id = params.id
            }

            let response = await axios.get(state.apiUrl,{
                params: urlParams
            });

            if (response.data.success == false) {
                router.push({name: 'Home'})
            }

            state.pages[params.page] = response.data.data;

            commit('pageLoaded', params);
        },
        async loadArticle ({ commit, state }, params) {

            let urlParams = {
                action: 'loadArticle',
                id: params.id
            }

            if (!state.articles[params.id]) {
                let response = await axios.get(state.apiUrl, {
                    params: urlParams
                });

                if (response.data.success == false) {
                    router.push({name: 'Home'})
                }

                state.articles[params.id] = response.data.data.article;
            }

            commit('articleLoaded', params);
        },
    }
})

export default store;
