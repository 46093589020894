export const isMobile = {
    data() {
        return {
            isMobile: false,
            breakPointWidth: 1024,
            windowWidth: 0,
            windowHeight: 0,
        }
    },

    mounted() {
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            //window.addEventListener('resize', this.getWindowHeight);

            //Init
            this.getWindowWidth()
            //this.getWindowHeight()
        });
    },

    watch: {
        windowWidth: function(val) {
            var _t = this;

            _t.isMobile = val < _t.breakPointWidth;
        }
    },

    methods: {
        getWindowWidth(event) {
            this.windowWidth = document.documentElement.clientWidth;
        },
        getWindowHeight(event) {
            this.windowHeight = document.documentElement.clientHeight;
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
            //window.removeEventListener('resize', this.getWindowHeight);
        },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        }
    }
};
