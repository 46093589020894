import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import Home from '@/components/Home.vue';

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: "/:catchAll(.*)",
      redirect: {name: 'Home'}
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    //history: createWebHistory(),
    routes,
})

export default router;
