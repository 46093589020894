export const functions = {
    methods: {
        get(val) {
            let _t = this;

            let page = 'App';

            if (_t.pageData && _t.pageData.page) {
                page = _t.pageData.page;
            }

            if (_t.$store.state.pages[page] && _t.$store.state.pages[page][val]) {
                return _t.$store.state.pages[page][val];
            }

            //значения из базового шаблона
            page = 'App';

            if (_t.$store.state.pages[page] && _t.$store.state.pages[page][val]) {
                return _t.$store.state.pages[page][val];
            }

            //return val;
            return '';
        },
        bgImgStyle: function(images){
            let stages = [];

            for (let i in images) {
                stages.push("url("+ images[i] +") " + i);
            }

            let str = "image-set(" + stages.join(',') + ")";

            return {
                "background-image": ['-webkit-' + str, str]
            }
        },
        imgSrc: function(images){
            for (let i in images) {
                return images[i];
            }
        },
        randomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        magic: function (attempt) {
            var _t = this;

            if (!attempt) {
                attempt = 0;

                $('.title-magic').removeAttr('style');
            }

            _t.$nextTick(function () {
                let _t = this;

                setTimeout(function () {
                    let blockWidth = $('.title-magic:last').width();
                    let articlesWidth = $('.title-magic:last > span').width();
                    let articleFontSize = parseInt($('.title-magic:last').css('font-size')) * 100 / $(window).width();
                    let articleLineHeight = parseInt($('.title-magic:last').css('line-height')) * 100 / $(window).width();

                    //console.log(articlesWidth, blockWidth, attempt, articleFontSize, articleLineHeight);

                    if (articlesWidth >= blockWidth && attempt < 100) {
                        $('.title-magic').css({
                            'font-size': (articleFontSize - 0.5) + 'vw',
                            'line-height': (articleLineHeight - 0.5) + 'vw',
                        });

                        //check it, анимация длится 0,3 секунды
                        _t.magic(++attempt);

                    } else {
                        _t.magicComplete = true;

                        $('.title-magic span').css({opacity: 1});
                    }
                }, 50);
            });
        },
    }
};
