<template>
  <div class="wrapper" id="wrapper" :class="{open: menuShowed == true}">

    <router-view v-slot="{ Component, route }"
                 @loaded="loaded"
                 @onBgChanged="onBgChanged"
    >
      <keep-alive>
        <component :is="Component" :key="$route.fullPath" ref="page"></component>
      </keep-alive>
    </router-view>

    <div class="br">
      <a :href="get('phone_link')">{{ get('phone') }}</a>
      <br v-if="isMobile">
      <template v-else> / </template>
      {{ get('address') }}
    </div>

    <div class="bl">
      {{ get('copyright') }}
    </div>

  </div>
</template>

<style lang="less">
@import './assets/css/style.less';
@import './assets/css/cursor.less';
</style>

<script>
import './assets/css/animate.css'

import {isMobile} from './mixins/isMobile';
import {functions} from './mixins/functions.js';

export default {
  name: 'App',
  mixins: [isMobile, functions],

  data() {
    return {
      currentPage: 'App',
      pageData: {page: 'App'},
      bgStyle: {},
    }
  },

  created() {
    this.$store.dispatch('loadData', {
      page: 'App'
    });
  },

  mounted() {
    var _t = this;

    let mobileOperationSystem = _t.getMobileOperatingSystem();

    if (mobileOperationSystem != 'unknown') {
      //$('.cursor').hide();
    }

    $('body').mousemove(function(e){
        if (mobileOperationSystem == 'unknown') {
          TweenMax.to($("#cursor"), 1.5, {
            delay: 0.1,
            left: e.clientX,
            top: e.clientY,
            ease: Elastic.easeOut.config(1.1, 1)
          });
          TweenMax.to($("#cursor-dot"), 0, {left: e.clientX, top: e.clientY});
        } else {
          $("#cursor").css({
            left: e.clientX,
            top: e.clientY,
          });
          $("#cursor-dot").css({
            left: e.clientX,
            top: e.clientY,
          });
        }
    });

    $(document).on('mouseover mouseout', '.hover, a, .link, .swiper-button-prev, .swiper-button-next', function(event) {

      if ($('.cursor').hasClass('cursor--crazy')) {
        return;
      }

      let classes = ['cursor--hover'];

      const _t = $(this);

      if (
          _t.hasClass('link') ||
          _t.hasClass('swiper-button-prev') ||
          _t.hasClass('swiper-button-next')
      ) {
        classes.push('cursor--big')
      }

      if (event.type == 'mouseover') {
        $('.cursor').addClass(classes.join(' '));
        $('.cursor-dot').addClass('cursor--hover');
      } else {
        $('.cursor').removeClass(classes.join(' '));
        $('.cursor-dot').removeClass('cursor--hover');
      }
    });

    $(document).on('mouseover mouseout', '.hover-black', function(event) {
      if ($('.cursor').hasClass('cursor--crazy')) {
        return;
      }

      if (event.type == 'mouseover') {
        $('.cursor').addClass('hover-black');
        $('.cursor-dot').addClass('hover-black');
      } else {
        $('.cursor').removeClass('hover-black');
        $('.cursor-dot').removeClass('hover-black');
      }
    });

    $(document).on('mouseover mouseout', '.btn', function(event) {
      if ($('.cursor').hasClass('cursor--crazy')) {
        return;
      }

      if (event.type == 'mouseover') {
        $('.cursor').addClass('cursor--filled');
      } else {
        $('.cursor').removeClass('cursor--filled');
      }
    });
    $(document).on('mouseover mouseout', '.swiper-button-prev', function(event) {
      if ($('.cursor').hasClass('cursor--crazy')) {
        return;
      }

      if (event.type == 'mouseover') {
        $('.cursor').addClass('cursor--prev');
      } else {
        $('.cursor').removeClass('cursor--prev');
      }
    });
    $(document).on('mouseover mouseout', '.swiper-button-next', function(event) {
      if ($('.cursor').hasClass('cursor--crazy')) {
        return;
      }

      if (event.type == 'mouseover') {
        $('.cursor').addClass('cursor--next');
      } else {
        $('.cursor').removeClass('cursor--next');
      }
    });
  },

  computed: {
    wrapperMode() {
      let classes = {};

      classes[this.$store.state.mode] = true;

      return classes;
    },
  },

  methods: {
    onBgChanged(bg) {
      const _t = this;
      _t.bgStyle = {};

      _t.bgStyle['background'] = bg;
    },
    loaded(data) {
      var _t = this;

      _t.menu = _t.get('menu');

      _t.currentPage = data.page ? data.page : false;
    },
  }
}
</script>
